/* SEARCH-TAG: page-template  */
import React, { useState, useEffect, useContext } from 'react';
import { graphql, navigate } from 'gatsby';
import { shape } from 'prop-types';
import styled from 'styled-components';
import { BreadcrumbsContext } from 'TMComponents/Breadcrumbs';
import HeadTags from 'TMComponents/HeadTags';
import NavLink from 'TMComponents/NavLink';
import FlexWrapper from 'TMComponents/FlexWrapper';
import Container from 'TMComponents/Container';
import Input from 'TMComponents/form/Input';
import ContentParser from 'TMComponents/ContentParser';
import Img from 'TMComponents/ImageDynamic';
import boldSubStr from 'TMUtils/boldSubStr';
import FAQCategory from 'TMComponents/FAQCategory';
import Canonical from 'TMComponents/Canonical';
import { BannerContext } from 'TMComponents/Banner';
import Hero from 'TMComponents/Hero';
import { LayoutContext } from 'TMComponents/Layout';
import Main from 'TMComponents/Layout/Main';
import Separator from 'TMComponents/Separator';
import isEnterKeyPressed from '../../utils/isEnterKeyPressed';

const Content = styled(Container)`
    padding: 0px 16px;
    padding-bottom: 32px;
`;

const CategoryContainer = styled(Container)`
    max-width: 568px;
`;

const Background = styled.div`
    ${({ theme }) => `
        background-color: ${theme.colorSystem.primary[3]};
        margin: auto -16px;
        padding-left: 16px;
        padding-right: 16px;
    `}
`;
const Bottom = styled.div`
    padding-top: 16px;
`;

const IconWrapper = styled(FlexWrapper)`
    margin-bottom: 16px;
`;

const Post = styled(ContentParser)`
    border-bottom: 1px solid ${(props) => props.theme.colors.greys.light};
    padding: 16px 0;
    text-align: left;

    p {
        margin: 0 !important;
    }

    &:first-child {
        border-top: 1px solid ${(props) => props.theme.colors.greys.light};
    }

    &:hover {
        text-decoration: underline;
    }
`;

const isBannerAllowed = true;
const isCrumbsAllowed = false;

const HelpSupport = ({ data: { supportLandingPage, allCategories } }) => {
    const [filter, setFilter] = useState('');
    const { setIsNavHidden } = useContext(LayoutContext);
    const { setShowBanner } = useContext(BannerContext);
    const { setCrumbs } = useContext(BreadcrumbsContext);

    useEffect(() => {
        setIsNavHidden(false);
        setShowBanner(isBannerAllowed);
        setCrumbs({
            show: isCrumbsAllowed,
            crumbList: [
                { title: 'Help & Support', link: '/help-support/' }
            ]
        });
    }, []);

    const allPosts = allCategories.nodes.reduce(
        (prev, { slug, posts }) =>
            prev.concat(
                posts.map((post) => ({
                    ...post,
                    url: `/help-support/${slug}/${post.slug}/`
                }))
            ),
        []
    );
    const posts = allPosts.filter(({ name }) =>
        name.toLowerCase().includes(filter.toLowerCase())
    );

    return (
        <Main
            isBannerAllowed={isBannerAllowed}
            isCrumbsAllowed={isCrumbsAllowed}>
            <HeadTags tags={supportLandingPage.seoMetaTags.tags} />
            <Canonical href="help-support" />
            <Hero
                content={supportLandingPage.heroContent}
                desktopImage={supportLandingPage.headerImageDesktop}
                mobileImage={supportLandingPage.headerImageMobile}
                desktopImagePosition={
                    supportLandingPage.desktopImagePosition
                }
                mobileImagePosition={
                    supportLandingPage.mobileImagePosition
                }
                imageCover={supportLandingPage.imageCover}
                backgroundVariant={supportLandingPage.backgroundVariant}>
                <Input
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    onKeyUp={(e) =>
                        isEnterKeyPressed(
                            e.code,
                            true,
                            '#article-help',
                            navigate
                        )
                    }
                    placeholder={supportLandingPage.searchPlaceholder}
                    search
                    isHelpAndSupport
                />
            </Hero>
            <Content>
                <Background>
                    {filter && <Separator anchor="article-help" />}
                    <CategoryContainer>
                        {!filter ? (
                            <FlexWrapper direction="column">
                                {allCategories.nodes.map(
                                    ({
                                        id,
                                        posts: categoryPosts,
                                        slug: categorySlug,
                                        ...rest
                                    }) => (
                                        <FAQCategory
                                            key={id}
                                            nameLink={`/help-support/${categorySlug}/`}
                                            posts={categoryPosts
                                                .filter(
                                                    ({ featured }) =>
                                                        !!featured
                                                )
                                                .map(
                                                    ({
                                                        slug,
                                                        ...restPost
                                                    }) => ({
                                                        url: `/help-support/${categorySlug}/${slug}/`,
                                                        ...restPost
                                                    })
                                                )
                                                .slice(0, 3)}
                                            {...rest}
                                            alwaysOpen
                                        />
                                    )
                                )}
                            </FlexWrapper>
                        ) : (
                            posts
                                .map((post) => ({
                                    ...post,
                                    name: boldSubStr(post.name, filter)
                                }))
                                .map((post) => (
                                    <NavLink
                                        key={post.slug}
                                        href={post.url}>
                                        <Post copy={post.name} />
                                    </NavLink>
                                ))
                        )}
                    </CategoryContainer>
                </Background>
                <Bottom>
                    <IconWrapper justify="center">
                        {supportLandingPage.bottomTextIcon && (
                            <Img
                                imageData={
                                    supportLandingPage.bottomTextIcon.icon
                                }
                            />
                        )}
                    </IconWrapper>
                    <ContentParser
                        copy={supportLandingPage.bottomText}
                        width={500}
                        centerAlignText
                    />
                </Bottom>
            </Content>
        </Main>
    );
};

/**
 * Fetch page data
 */
export const pageQuery = graphql`
    {
        supportLandingPage: datoCmsSupportLandingPage {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            headerImageDesktop {
                gatsbyImageData(
                    width: 768
                    layout: CONSTRAINED
                    imgixParams: { auto: "compress" }
                )
            }
            headerImageMobile {
                gatsbyImageData(
                    width: 768
                    layout: CONSTRAINED
                    imgixParams: { auto: "compress" }
                )
            }
            searchPlaceholder
            desktopImagePosition
            mobileImagePosition
            imageCover
            backgroundVariant
            heroContent {
                ...textStructuredFields
            }
            bottomTextIcon {
                icon {
                    gatsbyImageData(
                        width: 60
                        layout: FIXED
                        imgixParams: { auto: "compress" }
                    )
                }
            }
            bottomText
        }
        allCategories: allDatoCmsSupportCategory {
            nodes {
                __typename
                id
                name
                slug
                icon {
                    icon {
                        gatsbyImageData(
                            width: 40
                            layout: FIXED
                            imgixParams: { auto: "compress" }
                        )
                    }
                }
                posts {
                    __typename
                    id
                    name
                    slug
                    featured
                }
            }
        }
    }
`;

/**
 * Prop types
 */
HelpSupport.propTypes = {
    data: shape({
        supportLandingPage: shape({})
    }).isRequired
};

export default HelpSupport;
