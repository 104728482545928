const boldenMatches = (source, matches) => {
    let result = source;
    matches.forEach((match) => {
        result = result.replaceAll(match, `**${match}**`);
    });
    return result;
};

const removeRedundantBoldTags = (str) => {
    return str.replaceAll('****', '');
};

export default (str, subString) => {
    const regex = new RegExp(subString.trim(), 'gi');
    const matches = str.match(regex);

    if (!matches) return str;

    const uniqueMatches = matches.filter((value, index, self) => {
        return self.indexOf(value) === index;
    });

    const strWithBoldMatches = boldenMatches(str, uniqueMatches);

    return removeRedundantBoldTags(strWithBoldMatches);
};
